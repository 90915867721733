import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Text,
  VStack,
  HStack,
  Flex,
  Table,
  Tbody,
  Tr,
  Td,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';
import InstagramProfilePic from '../../components/InstagramProfilePic';
import { FaEnvelope, FaInstagram, FaStar, FaTiktok } from 'react-icons/fa';
import SubmissionsModal from '../../components/SubmissionsModal';
import CreateCampaignModal from '../../components/CreateCampaignModal';
import { Influencer, CollaborationsData, InfluencerCampaign } from '../../types';
import { getInfluencerByID } from 'services/firebaseService';

interface ActiveCampaignProps {
  campaign: InfluencerCampaign;
  collaborations: Record<string, CollaborationsData>;
  clientID: string;
}

const ActiveCampaign: React.FC<ActiveCampaignProps> = ({
  campaign,
  collaborations,
  clientID
}) => {
  const history = useHistory();
  const [isSubmissionsModalOpen, setIsSubmissionsModalOpen] = useState(false);
  const [isCreateCampaignModalOpen, setIsCreateCampaignModalOpen] = useState(false);
  const [selectedCollaboration, setSelectedCollaboration] = useState<CollaborationsData | null>(null);
  const [influencers, setInfluencers] = useState<Record<string, Influencer>>({});
  
  const boxContents = [
    { label: 'Likes', value: '0' },
    { label: 'Comments', value: '0' },
    { label: 'Posts', value: '0' },
    { label: 'Shares', value: '0' },
  ];

  const collabStates = {
    pendingInfluencerApproval: {
      label: 'Pending Influencer Approval',
      color: 'gray.300',
    },
    pendingRedemption: {
      label: 'Awaiting Restaurant Visit',
      color: 'gray.300',
    },
    collabRedeemed: {
      label: 'Pending Reward Redemption',
      color: '#B8E79E',
    },
    pendingSubmission: {
      label: 'Awaiting Content Submission',
      color: 'gray.300',
    },
    pendingResubmission: {
      label: 'Awaiting Failed Content Resubmission',
      color: 'red.300',
    },
    pendingVerification: {
      label: 'Awaiting Content Approval',
      color: 'gray.300',
    },
    collabCanceled: {
      label: 'Collab Canceled',
      color: 'red.300',
    },
    collabExpired: {
      label: 'Collab Expired',
      color: 'red.300',
    },
    collabCompleted: {
      label: 'View Completed Collab',
      color: '#B8E79E',
    },
  };

  useEffect(() => {
    const fetchInfluencers = async () => {
      const influencerData: Record<string, Influencer> = {};
      for (const collaboration of Object.values(collaborations)) {
        const influencer = await getInfluencerByID(collaboration.influencerID);
        if (influencer) {
          influencerData[collaboration.influencerID] = influencer;
        }
      }
      setInfluencers(influencerData);
    };

    fetchInfluencers();
  }, [collaborations]);

  const handleViewSubmissions = (collaboration: CollaborationsData) => {
    setSelectedCollaboration(collaboration);
    setIsSubmissionsModalOpen(true);
  };

  const handleViewDetails = () => {
    setIsCreateCampaignModalOpen(true);
  };

  const sortedCollaborations = Object.values(collaborations).sort((a, b) => {
    const priorityStatuses = ['collabCompleted'];
    if (priorityStatuses.includes(a.status) && !priorityStatuses.includes(b.status)) {
      return -1;
    }
    if (!priorityStatuses.includes(a.status) && priorityStatuses.includes(b.status)) {
      return 1;
    }
    return 0;
  });

  return (
    <Box bg="white" overflowY="auto" p={0} display="flex" flexDirection="column" minHeight="calc(100vh - 135px)">
      <Box width="90%" mx="auto" my={6} display="flex" flexDirection="column" height="calc(100vh - 48px)">
        <Flex padding={7} position="relative" justifyContent="space-between" h="20%" bg="#B8E79E" borderRadius="lg" mb={6}>
          <Text
            fontSize="2xl"
            fontWeight="bold"
            color="black"            
          >
            {campaign.name}
          </Text>
          <Flex gap={2}>
            <Button
              bg="#EAFBE0"
              color="black"
              _hover={{ bg: "gray.300" }}
              onClick={handleViewDetails}
            >
              View Details
            </Button>
            <Button
              bg="black"
              color="white"
              _hover={{ bg: "gray.800" }}
              onClick={() => history.push(`/admin/foodfluence/search?campaignID=${campaign.id}&clientID=${clientID}`)}
            >
              Add Influencers
            </Button>
          </Flex>
        </Flex>
        <Flex justifyContent="center" mt="-8%" mb={6}>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={6} width="95%">
            {boxContents.map(({ label, value }, index) => (
              <Box
                key={index}
                bg="white"
                borderRadius="md"
                boxShadow="md"
                p={6}
                position="relative"
                height={{ base: '130px', md: '150px' }}
              >
                <VStack align="flex-start" spacing={3} height="100%">
                  <Text
                    fontSize={{ base: 'sm', md: 'md' }}
                    fontWeight="bold"
                    color="gray.700"
                  >
                    {label}
                  </Text>
                  <VStack align="flex-start" spacing={0} mt="auto">
                    <HStack spacing={2} alignItems="baseline">
                      <Text
                        fontSize={{ base: '2xl', md: '3xl' }}
                        fontWeight="bold"
                        color="black"
                      >
                        {value}
                      </Text>
                    </HStack>
                  </VStack>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </Flex>
        <Text
          fontSize="xl"
          fontWeight="bold"
          color="black"
          mb={4}
          pl={4}
        >
          Collaborations
        </Text>
        <Box overflowY="auto" flex={1} px={2} mb={8}>
          <Table variant="simple">
            <Tbody>
              {sortedCollaborations.map((collaboration) => {
                const influencer = influencers[collaboration.influencerID];
                return (
                  <Tr key={collaboration.id}>
                    <Td py={4}>
                      <Flex alignItems="center" gap={6}>
                        <InstagramProfilePic
                          profile_pic_url={influencer?.profile_pic_url}
                          instagramHandle={influencer?.instagramHandle}
                          imageSize="89px"
                        />
                        <VStack align="flex-start" spacing={1}>
                          <Flex alignItems="center" gap={2}>
                            <Text fontWeight="bold">@{influencer?.instagramHandle}</Text>
                            <Flex gap={1}>
                              {influencer?.featured && 
                                (<IconButton icon={<FaStar color="#0080FE" size={16} />} aria-label="Featured" variant="ghost" colorScheme="green" />
                              )}
                              {influencer?.email_address && (
                                <IconButton icon={<FaEnvelope size={16} />} aria-label="Email" variant="ghost" colorScheme="gray" as="a" href={`mailto:${influencer?.email_address}`} />
                              )}
                              <IconButton icon={<FaInstagram size={16} />} aria-label="Instagram" variant="ghost" colorScheme="gray" as="a" href={`https://instagram.com/${influencer?.instagramHandle}`} target="_blank" rel="noopener noreferrer" />
                              {influencer?.tiktokHandle && (
                                <IconButton icon={<FaTiktok size={16} />} aria-label="TikTok" variant="ghost" colorScheme="gray" as="a" href={`https://www.tiktok.com/@${influencer?.tiktokHandle}`} target="_blank" rel="noopener noreferrer" />
                              )}
                            </Flex>
                          </Flex>
                          <Text fontSize="sm" color="gray.600">
                            {influencer?.city && influencer?.state ? `${influencer.city}, ${influencer.state} | ` : ''}
                            {influencer?.follower_count.toLocaleString()} followers
                          </Text>
                        </VStack>
                      </Flex>
                    </Td>
                    <Td textAlign="right">
                      <Button
                        bg={collabStates[collaboration.status].color}
                        color="black" 
                        size="md"
                        px={6}
                        onClick={collaboration.status === 'collabCompleted' ? () => handleViewSubmissions(collaboration) : () => {}}
                      >
                        {collabStates[collaboration.status].label}
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Box>
      {selectedCollaboration && (
        <SubmissionsModal
          isOpen={isSubmissionsModalOpen}
          onClose={() => setIsSubmissionsModalOpen(false)}
          collaboration={selectedCollaboration}
          influencer={influencers[selectedCollaboration.influencerID]}
        />
      )}
      <CreateCampaignModal
        isOpen={isCreateCampaignModalOpen}
        onClose={() => setIsCreateCampaignModalOpen(false)}
        clientID={clientID}
        campaignData={campaign}
      />
    </Box>
  );
};

export default ActiveCampaign;