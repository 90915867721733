import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, IconButton, Spinner } from '@chakra-ui/react';
import { FaInstagram, FaEnvelope, FaTiktok, FaStar } from 'react-icons/fa';
import InstagramProfilePic from './InstagramProfilePic';
import { getFeaturedInfluencers } from 'services/firebaseService';
import { Influencer } from '../types';

const FeaturedInfluencers: React.FC = () => {
  const [featuredInfluencers, setFeaturedInfluencers] = useState<Influencer[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFeaturedInfluencers = async () => {
      setIsLoading(true);
      try {
        const influencers = await getFeaturedInfluencers();
        setFeaturedInfluencers(influencers);
      } catch (error) {
        console.error("Error fetching featured influencers:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFeaturedInfluencers();
  }, []);

  const formatFollowersCount = (followers: number): string => {
    if (followers >= 1000 && followers < 1000000) {
      return (followers / 1000).toFixed(1) + 'k';
    } else if (followers >= 1000000) {
      return (followers / 1000000).toFixed(1) + 'M';
    }
    return followers.toString();
  };


  return (
    <Flex
      overflowX="auto"
      p={4}
      alignItems="flex-start"
      gap={10}
      sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
      maxWidth="89%"
      mx="auto"
      ml={8}
      position="relative"
      minHeight="300px"
    >
      {isLoading ? (
        <Flex
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <Spinner size="xl" color="blue.500" />
        </Flex>
      ) : (
        featuredInfluencers.map(influencer => (
          <Box
            key={influencer.id}
            bg="white"
            borderRadius="md"
            boxShadow="md"
            pt={5}
            px={5}
            textAlign="center"
            width="400"
            height="300"
            flexDirection="column"
            gap={4}
          >
            <Flex direction="column" gap={2} alignItems="center" justifyContent="center">
              <InstagramProfilePic profile_pic_url={influencer.profile_pic_url}
              instagramHandle={influencer.instagramHandle}
              imageSize="120px" />
              <Flex alignItems="center" direction="row" justifyContent="center" gap={4}>
                <Text fontSize="22" fontWeight="normal" color="black">
                  {influencer.instagramHandle}
                </Text>
                <FaStar color="#0080FE" size={22} />
              </Flex>
              <Flex justifyContent="center" gap={6}>
                {influencer.email_address && (
                  <IconButton icon={<FaEnvelope size={22} />} aria-label="Email" variant="ghost" colorScheme="gray" as="a" href={`mailto:${influencer.email_address}`} />
                )}
                <IconButton icon={<FaInstagram size={22} />} aria-label="Instagram" variant="ghost" colorScheme="gray" as="a" href={`https://instagram.com/${influencer.instagramHandle}`} target="_blank" rel="noopener noreferrer" />
                {influencer.tiktokHandle && (
                  <IconButton icon={<FaTiktok size={22} />} aria-label="TikTok" variant="ghost" colorScheme="gray" as="a" href={`https://www.tiktok.com/@${influencer.tiktokHandle}`} target="_blank" rel="noopener noreferrer" />
                )}
              </Flex>
              <Text color="gray.500" fontSize={18}>
                {formatFollowersCount(influencer.follower_count)} followers
              </Text>
            </Flex>
          </Box>
        ))
      )}
    </Flex>
  );
};

export default FeaturedInfluencers;
