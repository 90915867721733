import React, { useState,useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Icon,
  Image,
  AspectRatio,
  SimpleGrid,
  useDisclosure,
  IconButton,
  Link
} from '@chakra-ui/react';
import InstagramProfilePic from './InstagramProfilePic';
import { FaMapMarkerAlt, FaInstagram } from 'react-icons/fa';
import { RiContactsBookLine } from 'react-icons/ri';
import { FaPlay } from 'react-icons/fa';
import { CollaborationsData, Influencer } from '../types';

interface MediaItem {
  userLink: string;
  verifiedUGCLink: string;
}


interface SubmissionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  collaboration: CollaborationsData;
  influencer: Influencer;
}

const SubmissionsModal: React.FC<SubmissionsModalProps> = ({ isOpen, onClose, collaboration, influencer }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedMedia, setSelectedMedia] = useState<MediaItem | null>(null);
  const { isOpen: isExpandedViewOpen, onOpen: onExpandedViewOpen, onClose: onExpandedViewClose } = useDisclosure();
  const videoRef = useRef<HTMLVideoElement>(null);

  const isVideo = (url: string) => url.includes('.mp4') || url.includes('.mov') || url.includes('.avi');

  const handleMediaClick = (media: MediaItem) => {
    setSelectedMedia(media);
    onExpandedViewOpen();
  };

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const renderMedia = (mediaArray: MediaItem[]) => {
    return (
      <SimpleGrid columns={[1, 2, 3]} spacing={6}>
        {mediaArray.map((media, index) => (
          <Box key={index} mb={4}>
            <AspectRatio maxW="300px" ratio={1} onClick={() => handleMediaClick(media)}>
              {isVideo(media.verifiedUGCLink) ? (
                  <Box position="relative" width="300px" height="300px">
                  <video ref={videoRef} >
                    <source src={media.verifiedUGCLink} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <IconButton
                    icon={<FaPlay />}
                    aria-label="Play"
                    position="absolute"
                    left="50%"
                    top="50%"
                    transform="translate(-50%, -50%)"
                    fontSize="2xl"
                    color="white"
                    background="rgba(0, 0, 0, 0.5)"
                    borderRadius="full"
                    onClick={handlePlayPause}
                  />
                </Box>
              ) : (
                <Image
                  src={media.verifiedUGCLink}
                  alt={`Media ${index + 1}`}
                  objectFit="cover"
                  width="100%"
                  height="100%"
                />
              )}
            </AspectRatio>
            <Link
            mt={2}
            href={media.userLink}
            target="_blank"
            rel="noopener noreferrer"
            color="blue.500"
            fontWeight="bold"
            _hover={{ textDecoration: 'underline' }}
          >
            Go to Post
          </Link>
          </Box>
        ))}
      </SimpleGrid>
    );
  };

  const allMedia = [
    ...collaboration.deliverableLinks.instagramPosts,
    ...collaboration.deliverableLinks.instagramReels,
    ...collaboration.deliverableLinks.instagramStories,
    ...collaboration.deliverableLinks.tiktoks,
  ];

  const hasInstagramPosts = collaboration.deliverableLinks.instagramPosts.length > 0;
  const hasInstagramReels = collaboration.deliverableLinks.instagramReels.length > 0;
  const hasInstagramStories = collaboration.deliverableLinks.instagramStories.length > 0;
  const hasTikToks = collaboration.deliverableLinks.tiktoks.length > 0;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent maxW="60vw" maxH="90vh" border="2px solid #B8E79E">
          <ModalCloseButton />
          <ModalBody pt={16} pb={12} px={16}>
            <Flex alignItems="center" mb={8}>
            <InstagramProfilePic
              profile_pic_url={influencer?.profile_pic_url}
              instagramHandle={influencer?.instagramHandle}
              imageSize="120px"
            />
              <Box ml={8}>
                <Text fontWeight="bold" fontSize="xl">@{influencer?.instagramHandle}</Text>
                  {influencer?.city && influencer?.state && (
                  <Flex alignItems="center" mt={2}>
                    <Icon as={FaMapMarkerAlt} color="gray.500" mr={2} />
                    <Text fontSize="md">{influencer?.city}, {influencer?.state}</Text>
                  </Flex>
                )}
                <Flex alignItems="center" mt={2}>
                  <Icon as={FaInstagram} color="gray.500" mr={2} />
                  <Text fontSize="md">{influencer?.follower_count.toLocaleString()} followers</Text>
                </Flex>
              </Box>
            </Flex>

            <Tabs variant="line" colorScheme="blue" onChange={(index) => setTabIndex(index)}>
              <TabList mb="1.5em">
                {(hasInstagramPosts || hasInstagramReels || hasInstagramStories || hasTikToks) && <Tab fontWeight="semibold">All</Tab>}
                {hasTikToks && <Tab fontWeight="semibold">TikToks</Tab>}
                {hasInstagramReels && <Tab fontWeight="semibold">Instagram Reels</Tab>}
                {hasInstagramStories && <Tab fontWeight="semibold">Instagram Stories</Tab>}
                {hasInstagramPosts && <Tab fontWeight="semibold">Instagram Posts</Tab>}
              </TabList>

              <TabPanels>
                {(hasInstagramPosts || hasInstagramReels || hasInstagramStories || hasTikToks) && (
                  <TabPanel>
                    <Box height="45vh" overflowY="auto">
                      {renderMedia(allMedia)}
                    </Box>
                  </TabPanel>
                )}
                {hasTikToks && (
                  <TabPanel>
                    <Box height="45vh" overflowY="auto">
                      {renderMedia(collaboration.deliverableLinks.tiktoks)}
                    </Box>
                  </TabPanel>
                )}
                {hasInstagramReels && (
                  <TabPanel>
                    <Box height="45vh" overflowY="auto">
                      {renderMedia(collaboration.deliverableLinks.instagramReels)}
                    </Box>
                  </TabPanel>
                )}
                {hasInstagramStories && (
                  <TabPanel>
                    <Box height="45vh" overflowY="auto">
                      {renderMedia(collaboration.deliverableLinks.instagramStories)}
                    </Box>
                  </TabPanel>
                )}
                {hasInstagramPosts && (
                  <TabPanel>
                    <Box height="45vh" overflowY="auto">
                      {renderMedia(collaboration.deliverableLinks.instagramPosts)}
                    </Box>
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Expanded Media Modal */}
      <Modal isOpen={isExpandedViewOpen} onClose={onExpandedViewClose} size="lg"
        isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={0}>
            {selectedMedia && (
              isVideo(selectedMedia.verifiedUGCLink) ? (
                  <video controls autoPlay>
                    <source src={selectedMedia.verifiedUGCLink} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
              ) : (
                <Image
                  src={selectedMedia.verifiedUGCLink}
                  alt="Expanded Media"
                  boxSize="100%"
                  objectFit="contain"
                />
              )
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SubmissionsModal;
