import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Flex,
  Box,
  Input,
  Textarea,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
  Select,
  Stack,
  Link
} from '@chakra-ui/react';
import { useHistory, Route, Switch } from 'react-router-dom';
import { FaPlus, FaMinus, FaEye, FaRedo } from 'react-icons/fa';
import { createNewInfluencerCampaign, uploadCollabImage, updateInfluencerCampaign } from 'services/firebaseService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdUpload,MdFindReplace,MdFileDownload, MdDateRange } from "react-icons/md";
import { InfluencerCampaign, DeliverableCounts } from '../types';
import { Timestamp } from 'firebase/firestore';

interface CreateCampaignModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientID: String;
  campaignData?: InfluencerCampaign; // Optional parameter for existing campaign data
}

const CreateCampaignModal: React.FC<CreateCampaignModalProps> = ({ isOpen, onClose, clientID, campaignData }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [tikTokCount, setTikTokCount] = useState(0);
  const [instagramStoryCount, setInstagramStoryCount] = useState(0);
  const [instagramReelCount, setInstagramReelCount] = useState(0);
  const [instagramPostCount, setInstagramPostCount] = useState(0);
  const [campaignName, setCampaignName] = useState('');
  const [campaignDescription, setCampaignDescription] = useState('');
  const [rewardType, setRewardType] = useState<string>('cash');
  const [compensation, setCompensation] = useState<string>('0');
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [collabImage, setCollabImage] = useState<string | null>(null);
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (campaignData) {
      setCampaignName(campaignData.name);
      setCampaignDescription(campaignData.description);
      setTikTokCount(campaignData.deliverableCounts.tiktoks as number);
      setInstagramStoryCount(campaignData.deliverableCounts.instagramStories as number);
      setInstagramReelCount(campaignData.deliverableCounts.instagramReels as number);
      setInstagramPostCount(campaignData.deliverableCounts.instagramPosts as number);
      setRewardType(campaignData.rewardType);
      setCompensation(campaignData.compensation.toString());
      setEndDate(campaignData.endDate instanceof Timestamp ? campaignData.endDate.toDate() : null);
      setCollabImage(campaignData.collabImage);
      setShowUploadButton(false);
      setIsEditing(true);
    } else {
      // Reset state for new campaign creation
      setIsEditing(false);
      setHasChanges(false);
      setCampaignName('');
      setCampaignDescription('');
      setTikTokCount(0);
      setInstagramStoryCount(0);
      setInstagramReelCount(0);
      setInstagramPostCount(0);
      setRewardType('cash');
      setCompensation('0');
      setEndDate(new Date());
      setCollabImage(null);
      setShowUploadButton(true);
    }
  }, [campaignData]);

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<any>>) => (value: any) => {
    setter(value);
    if (isEditing) setHasChanges(true);
  };

  const handleFormSubmission = async () => {
    if (isEditing) {
      if (hasChanges) {
        const updatedData: Partial<InfluencerCampaign> = {
          name: campaignName,
          description: campaignDescription,
        };
        await updateInfluencerCampaign(campaignData.id, updatedData);
        setHasChanges(false);
      }
      onClose();
      return;
    }

  

    const deliverableCounts: DeliverableCounts = {
      'tiktoks': tikTokCount,
      'instagramReels': instagramReelCount,
      'instagramStories': instagramStoryCount,
      'instagramPosts': instagramPostCount,
    };
    
    const campaignID = await createNewInfluencerCampaign(clientID, campaignName, campaignDescription, deliverableCounts, rewardType, Number(compensation), endDate, collabImage)
    onClose();
    history.push(`/admin/foodfluence/search?campaignID=${campaignID}&clientID=${clientID}`);
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);

      const collabImageURL = await uploadCollabImage(clientID, campaignName, uint8Array);
      setCollabImage(collabImageURL);
      setShowUploadButton(false);
      if (isEditing) setHasChanges(true);
    }
  };
  
  const isFormValid = () => {
    return campaignName && campaignDescription && rewardType && compensation && endDate && (tikTokCount > 0 || instagramStoryCount > 0 || instagramReelCount > 0 || instagramPostCount > 0);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="80vw" maxWidth={"800px"} border="2px solid green.500">
        <ModalHeader>{isEditing ? 'Campaign Details' : 'Create a Campaign'}</ModalHeader>
        <ModalBody>
          <Box mb={4}>
            <Text>Campaign Name</Text>
            <Input
              placeholder="Collaboration Name"
              value={campaignName}
              onChange={(e) => handleInputChange(setCampaignName)(e.target.value)}
              mb={4}
            />
            <Text>Describe the campaign</Text>
            <Textarea
              placeholder="What are you looking to create with this campaign?"
              value={campaignDescription}
              onChange={(e) => handleInputChange(setCampaignDescription)(e.target.value)}
              mb={4}
            />
            <Text fontWeight="bold" mb={2}>Content Types:</Text>
            <Stack spacing={4}>
              {[
                { label: 'TikTok', count: tikTokCount, setCount: setTikTokCount },
                { label: 'Instagram Story', count: instagramStoryCount, setCount: setInstagramStoryCount },
                { label: 'Instagram Reel', count: instagramReelCount, setCount: setInstagramReelCount },
                { label: 'Instagram Post', count: instagramPostCount, setCount: setInstagramPostCount }
              ].map(({ label, count, setCount }) => (
                <Flex key={label} alignItems="center" justifyContent="space-between">
                  <Text>{label}</Text>
                  <Flex alignItems="center">
                    <IconButton
                      icon={<FaMinus style={{ color: 'red' }} />}
                      aria-label={`Decrease ${label} count`}
                      onClick={() => handleInputChange(setCount)(count - 1)}
                      isDisabled={isEditing}
                    />
                    <Text mx={2}>{count}</Text>
                    <IconButton
                      icon={<FaPlus style={{ color: 'green' }} />}
                      aria-label={`Increase ${label} count`}
                      onClick={() => handleInputChange(setCount)(count + 1)}
                      isDisabled={isEditing}
                    />
                  </Flex>
                </Flex>
              ))}
            </Stack>
            <Box mt={4}>
              <Text fontWeight="bold" mb={2}>Reward Type:</Text>
              <Select
                value={rewardType}
                onChange={(e) => handleInputChange(setRewardType)(e.target.value as 'cash' | 'diningCredit')}
                isDisabled={isEditing}
              >
                <option value="cash">Cash</option>
                <option value="diningCredit">Dining Credit</option>
              </Select>
            </Box>
            <Box mt={4}>
              <Input
                type="text"
                placeholder="Amount"
                value={compensation}
                onChange={(e) => handleInputChange(setCompensation)(e.target.value)}
                isReadOnly={isEditing}
              />
            </Box>
            <Box mt={4}>
              <Text fontWeight="bold" mb={2}>End Date:</Text>
              <DatePicker
                id="endDatePicker"
                selected={endDate}
                onChange={(date: Date) => handleInputChange(setEndDate)(date)}
                dateFormat="MMMM d, yyyy"
                minDate={new Date()}
                placeholderText="Select end date"
                disabled={isEditing}
              />
            </Box>
            <Flex alignItems="center" justifyContent="space-between" mt={4}>
              <Text fontWeight="bold" mb={2}>Upload Image (Optional):</Text>
              {showUploadButton ? (
                <Button
                  rightIcon={<MdUpload />}
                  aria-label="Upload Image"
                  onClick={() => inputRef.current?.click()}
                  bg="black"
                  color="white"
                  position="relative"
                  mt={2}
                  _hover={{ bg: "blackAlpha.600" }}
                  isDisabled={isEditing}
                >
                  Upload
                </Button>
              ) : (
                <Flex>
                  <Link href={collabImage} isExternal>
                    <IconButton icon={<FaEye size={30} />} aria-label="View Image" color="green" />
                  </Link>
                  {!isEditing && (
                    <Button
                      ml={2}
                      bg="red.500"
                      color="white"
                      _hover={{ bg: "red.600" }}
                      onClick={() => {
                        setShowUploadButton(true);
                        if (isEditing) setHasChanges(true);
                      }}
                      borderRadius="full"
                    >
                      <FaRedo size={12} />
                    </Button>
                  )}
                </Flex>
              )}
            </Flex>
            <Input
              type="file"
              ref={inputRef}
              display="none"
              onChange={handleFileChange}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          {isEditing ? (
            <Button colorScheme="green" mr={3} onClick={handleFormSubmission} isDisabled={!hasChanges}>
              Update
            </Button>
          ) : (
            <Button colorScheme="green" mr={3} onClick={() => handleFormSubmission()} isDisabled={!isFormValid()}>
              Create
            </Button>
          )}
          <Button variant="ghost" onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateCampaignModal;