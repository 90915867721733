import React, { useContext, useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Progress,
  Flex,
} from '@chakra-ui/react';
import { InfluencerCampaign } from '../types';


interface ActiveCampaignsTableProps {
  influencerCampaigns: Record<string, InfluencerCampaign>;
  onCampaignClick: (campaignId: string) => void;
}

// export const getTotalActiveCreators = (collaborations: Record<string, CollaborationsData>, influencerCampaigns: InfluencerCampaign[]) => {
//   const activeCreators = new Set<string>();

//   influencerCampaigns.forEach(campaign => {
//     Object.values(collaborations).forEach(collab => {
//       if (collab.influencerCampaignID === campaign.id) {
//         activeCreators.add(collab.influencerID);
//       }
//     });
//   });

//   return activeCreators.size;
// };

const ActiveCampaignsTable: React.FC<ActiveCampaignsTableProps> = ({ influencerCampaigns, onCampaignClick}) => {


  const getActiveCreators = (influencerCampaign: InfluencerCampaign) => {
    if (!influencerCampaign.collaborations) return 0;

    return Object.values(influencerCampaign.collaborations).length;
  };

  const calculateProgress = (influencerCampaign: InfluencerCampaign) => {
   
    const completedCollaborations = Object.values(influencerCampaign.collaborations).filter(
      collab => collab.status == "collabCompleted"
    );

    return Object.values(influencerCampaign.collaborations).length > 0
      ? Math.round((completedCollaborations.length / Object.values(influencerCampaign.collaborations).length) * 100)
      : 0;
      
  };

  const getTotalPosts = (influencerCampaign: InfluencerCampaign) => {
    if (!influencerCampaign.collaborations) return 0;

    const completedCollaborations = Object.values(influencerCampaign.collaborations).filter(
      collab => collab.status === "collabCompleted"
    );

    return completedCollaborations.reduce((total, collab) => {
      const { instagramPosts, instagramReels, instagramStories, tiktoks } = collab.deliverableCounts;
      return total + instagramPosts + instagramReels + instagramStories + tiktoks;
    }, 0);
  };

  const handleRowClick = (campaign: InfluencerCampaign) => {
    onCampaignClick(campaign.id);
  };

  const sortedCampaigns = Object.values(influencerCampaigns)
    .sort((a, b) => calculateProgress(b) - calculateProgress(a));

  return (
    <Box bg="white" pt={3} borderRadius="md" boxShadow="md" maxWidth="90%" mx="auto" ml={4}>
      <Text p={3} fontSize="xl" fontWeight="bold">
        Active Campaigns
      </Text>
      <TableContainer maxHeight="200px" overflowY="auto">
        <Table variant="simple">
          <Thead bg="gray.300" position="sticky" top={0} zIndex={1}>
            <Tr>
              <Th>Campaign Name</Th>
              <Th>Total Posts</Th>
              <Th>Creators</Th>
              <Th>Progress</Th>
            </Tr>
          </Thead>
          <Tbody>
          {sortedCampaigns.length > 0 ? sortedCampaigns.map((influencerCampaign) => (
            <Tr 
              key={influencerCampaign.id} 
              onClick={() => handleRowClick(influencerCampaign)}
              cursor="pointer"
              _hover={{ bg: "gray.100" }}
            >
              <Td>{influencerCampaign.name}</Td>
              <Td>{getTotalPosts(influencerCampaign)}</Td>
              <Td>{getActiveCreators(influencerCampaign)}</Td>
              <Td>
                <Flex alignItems="center">
                  <Text mr={2}>{calculateProgress(influencerCampaign)}%</Text>
                  <Progress 
                    value={calculateProgress(influencerCampaign)} 
                    size="sm" 
                    colorScheme="green" 
                    width="100px"
                    bg="gray.300"  // Slightly lighter grey background
                  />
                </Flex>
              </Td>
            </Tr>
          )) : (
            <>
              <Tr>
                <Td colSpan={4} textAlign="center">Active campaigns will show up here</Td>
              </Tr>
              <Tr>
                <Td colSpan={4} textAlign="center"></Td>
              </Tr>
            </>
          )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ActiveCampaignsTable;